var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1 mr-1 mb-5" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-create mb-1",
                      attrs: {
                        type: "button",
                        variant: "primary",
                        disabled: _vm.isLoading || !_vm.uniquePdfIds.length,
                      },
                      on: { click: _vm.onGenerateUniquePdf },
                    },
                    [_vm._v("Genera PDF")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-create mb-1",
                      attrs: {
                        type: "button",
                        variant: "primary",
                        disabled: _vm.isLoading,
                      },
                      on: { click: _vm.onOpenBrokerStatementModal },
                    },
                    [_vm._v("Invia selezionati")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              attrs: { id: "collapse-1" },
              model: {
                value: _vm.showCollapse1,
                callback: function ($$v) {
                  _vm.showCollapse1 = $$v
                },
                expression: "showCollapse1",
              },
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "cod_prod",
                                label: "Produttore",
                                options: _vm.salesmen,
                              },
                              model: {
                                value: _vm.salesman_id,
                                callback: function ($$v) {
                                  _vm.salesman_id = $$v
                                },
                                expression: "salesman_id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "from",
                                label: "Registrazione Dal",
                              },
                              model: {
                                value: _vm.filter.byCalendar.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "from", $$v)
                                },
                                expression: "filter.byCalendar.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: { name: "to", label: "Registrazione Al" },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "iniziale_from",
                                label: "Data iniziale rendiconto dal",
                              },
                              model: {
                                value: _vm.filter.byFrom.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byFrom, "from", $$v)
                                },
                                expression: "filter.byFrom.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "iniziale_to",
                                label: "Data iniziale rendiconto al",
                              },
                              model: {
                                value: _vm.filter.byFrom.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byFrom, "to", $$v)
                                },
                                expression: "filter.byFrom.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "finale_from",
                                label: "Data finale rendiconto dal",
                              },
                              model: {
                                value: _vm.filter.byTo.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byTo, "from", $$v)
                                },
                                expression: "filter.byTo.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "finale_to",
                                label: "Data finale rendiconto al",
                              },
                              model: {
                                value: _vm.filter.byTo.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byTo, "to", $$v)
                                },
                                expression: "filter.byTo.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "collected",
                                label: "Incassato",
                                options: [
                                  { value: "Y", text: "Si" },
                                  { value: "N", text: "No" },
                                ],
                              },
                              model: {
                                value: _vm.filter.byAttribute.is_collected,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byAttribute,
                                    "is_collected",
                                    $$v
                                  )
                                },
                                expression: "filter.byAttribute.is_collected",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-input", {
                              attrs: { name: "id", label: "Id Rendiconto" },
                              model: {
                                value: _vm.filter.byAttribute.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byAttribute, "id", $$v)
                                },
                                expression: "filter.byAttribute.id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1652880295
              ),
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _c("broker-statements-accounting", {
                    ref: _vm.tableRef,
                    attrs: {
                      filterOn: {
                        byRelations: ["byBroker", "byDocument"],
                      },
                      fields: _vm.flds,
                      repository: _vm.repository,
                      resource: _vm.resource,
                      filterName: _vm.filterName,
                      onlyActions: ["infomodal", "destroy"],
                      noPagination: "",
                    },
                    on: {
                      destroy: _vm.removeBrokerStatement,
                      report: _vm.downloadReport,
                      selezionato: _vm.onUpdateSelected,
                      spunta: _vm.onSelectAll,
                      toglispunta: _vm.onUnselectAll,
                      spuntaunique: _vm.onUniqueSelectAll,
                      toglispuntaunique: _vm.onUniqueDeselectAll,
                      unique: _vm.onUnique,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("broker-statement-modal", {
                ref: _vm.modalRef,
                attrs: {
                  options: _vm.email_templates,
                  title:
                    "Stai per inviare via e-mail ai produttori tutti i rendiconti selezionati",
                },
                on: { send: _vm.onSend },
                model: {
                  value: _vm.lscmnc,
                  callback: function ($$v) {
                    _vm.lscmnc = $$v
                  },
                  expression: "lscmnc",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }