var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "div",
        [
          _c("b-table", {
            attrs: {
              busy: _vm.isLoading,
              "head-variant": "light",
              "no-local-sorting": true,
              items: _vm.items,
              fields: _vm.getAllFields,
              "current-page": _vm.currentPage,
              "per-page": "0",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "sort-direction": _vm.sortDirection,
              "sticky-header": _vm.height,
              "no-border-collapse": "",
              responsive: "",
              "show-empty": "",
              "empty-text": "Non ci sono informazioni da mostrare",
              small: "",
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
              "sort-changed": _vm.sortingChanged,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center my-2" },
                        [
                          _c("base-icon", {
                            attrs: {
                              name: "loading",
                              width: "35",
                              height: "35",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("Operazione in corso...")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.items.length
                  ? {
                      key: "cell(broker)",
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.toInfoData(item.broker, "broker", 0)
                              ),
                            },
                          }),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(rowSelector)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "action-buttons" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Apri Modale Custom",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onChosen(row.item)
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "eye" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                _vm.items.length
                  ? {
                      key: "cell(totalizers)",
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.toTotalizers(item)),
                            },
                          }),
                        ]
                      },
                    }
                  : null,
                _vm.items.length
                  ? {
                      key: "cell(files)",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "ul",
                            _vm._l(item.documents, function (document, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: { href: "download" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.downloadReport(document)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(document.title),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(selezionato)",
                  fn: function ({ item }) {
                    return [
                      _c("base-checkbox", {
                        staticClass: "check",
                        attrs: {
                          name: "selected",
                          chk_val: "Y",
                          unchk_val: "N",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelezionato($event, item)
                          },
                        },
                        model: {
                          value: item.is_selected.value,
                          callback: function ($$v) {
                            _vm.$set(item.is_selected, "value", $$v)
                          },
                          expression: "item.is_selected.value",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(actions)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "action-buttons" },
                        [
                          _vm.actions.includes("infomodal") && _vm.is_STATMANB()
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Modale Dettagli",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openModal(
                                        row.item,
                                        row.index,
                                        $event.target
                                      )
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "eye" } })],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("details")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: row.detailsShowing
                                      ? "Chiudi dettagli"
                                      : "Apri dettagli",
                                  },
                                  on: { click: row.toggleDetails },
                                },
                                [
                                  row.detailsShowing
                                    ? _c("b-icon", {
                                        attrs: { icon: "chevron-up" },
                                      })
                                    : _c("b-icon", {
                                        attrs: { icon: "chevron-down" },
                                      }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("edit")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Modifica",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEdit(row.item.id)
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "pencil-square" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("destroy")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Elimina",
                                    disabled: _vm.isCollected(row.item),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDestroy(row.item.id)
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "trash" } })],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(uniquePdfRow)",
                  fn: function ({ item }) {
                    return [
                      _c("base-checkbox", {
                        staticClass: "check",
                        attrs: {
                          name: "uniquePdfRow",
                          chk_val: true,
                          unchk_val: false,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onChosen(item, "unique")
                          },
                        },
                        model: {
                          value: item.uniquePdfRow,
                          callback: function ($$v) {
                            _vm.$set(item, "uniquePdfRow", $$v)
                          },
                          expression: "item.uniquePdfRow",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "row-details",
                  fn: function (row) {
                    return [
                      _c(
                        "b-card",
                        _vm._l(row.item, function (value, key) {
                          return _c(
                            "b-row",
                            { key: key, staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-sm-right",
                                  attrs: { sm: "3" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm._f("capitalize")(key)) + ":"
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("b-col", [_vm._v(_vm._s(value))]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasChecksButtonGroup
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    { attrs: { size: "md" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-decoration-none",
                          attrs: { variant: "transparent" },
                          on: {
                            click: function ($event) {
                              return _vm.spuntaTutte()
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            staticClass: "check-icon",
                            attrs: { icon: "check" },
                          }),
                          _vm._v("Spunta tutte\n        "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-decoration-none",
                          attrs: { variant: "transparent" },
                          on: {
                            click: function ($event) {
                              return _vm.togliSpuntaTutte()
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            staticClass: "cross-icon",
                            attrs: { icon: "x" },
                          }),
                          _vm._v("Togli spunte a tutte\n        "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "select-all" }, [
                        _vm._v("Selezionate: " + _vm._s(_vm.cntr)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button-group",
                    { attrs: { size: "md" } },
                    [
                      _c("p", { staticClass: "select-all" }, [
                        _vm._v("Selezionate: " + _vm._s(_vm.cntrunq)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "mb-2 ml-2",
                          attrs: {
                            pressed: _vm.myToggleUnique,
                            variant: "light",
                          },
                          on: {
                            "update:pressed": function ($event) {
                              _vm.myToggleUnique = $event
                            },
                            click: function ($event) {
                              _vm.myToggleUnique
                                ? _vm.spuntaUniqueTutti()
                                : _vm.togliSpuntaUniqueTutti()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.myToggleUnique
                                ? "Deseleziona tutti"
                                : "Seleziona tutti"
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.noPagination
        ? _c("b-row", [
            _c("div", { staticClass: "show-text" }, [
              _c("p", [_vm._v("Mostra")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "group" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-for": "per-page-select",
                      "label-cols-sm": "3",
                      "label-cols-md": "",
                      "label-cols-lg": "3",
                      "label-size": "sm",
                      md: "auto",
                    },
                  },
                  [
                    _c("b-form-select", {
                      staticStyle: { width: "68px" },
                      attrs: {
                        id: "per-page-select",
                        options: _vm.pageOptions,
                        size: "sm",
                        disabled: !_vm.items.length,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onPageChange(1)
                        },
                      },
                      model: {
                        value: _vm.perPage,
                        callback: function ($$v) {
                          _vm.perPage = $$v
                        },
                        expression: "perPage",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "page" }, [
              _c("p", [_vm._v("di " + _vm._s(_vm.totalItems) + " per pagina")]),
            ]),
            _vm._v(" "),
            _vm.totalItems
              ? _c(
                  "div",
                  { staticClass: "pagination" },
                  [
                    _c("b-pagination", {
                      attrs: {
                        limit: "3",
                        "total-rows": _vm.totalItems,
                        "per-page": _vm.perPage,
                        size: "sm",
                      },
                      on: { change: _vm.onPageChange },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.infomodalName,
            title: _vm.modalTitle,
            size: "xl",
            "ok-only": "",
            "ok-variant": "lisaweb",
            "header-bg-variant": "lisaweb",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.onModalHidden },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-tabs",
                {
                  attrs: { "content-class": "pt-1", lazy: "" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                _vm._l(_vm.tabs, function (tab, index) {
                  return _c(
                    "b-tab",
                    {
                      key: index,
                      attrs: {
                        active: index === _vm.tabIndex,
                        title: tab.text,
                        "title-item-class": "lisaweb",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setTab(tab, index)
                        },
                      },
                    },
                    [
                      _c("button-group-tab"),
                      _vm._v(" "),
                      _c(
                        "keep-alive",
                        [
                          _vm.selected === tab.name
                            ? _c(_vm.selected, {
                                tag: "component",
                                attrs: { resourceId: _vm.modalResourceId },
                                on: { fetch: _vm.fetch, close: _vm.closeModal },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }