var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _vm._l(_vm.dtlFlds, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        title: index,
                        header: item.header,
                        edit: item.edit || false,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                    })
                  }),
                  _vm._v(" "),
                  _vm.details.is_collected.value === "N"
                    ? _c(
                        "div",
                        [
                          _c("validation-observer", {
                            ref: "observer",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ invalid, handleSubmit }) {
                                    return [
                                      _c(
                                        "b-card",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            header: "Incassa Importo",
                                            "header-tag": "header",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-card-text",
                                            [
                                              _c("b-row", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3",
                                                  },
                                                  [
                                                    _c("base-datepicker", {
                                                      attrs: {
                                                        vid: "occurred_at",
                                                        name: "Data",
                                                        label: "Data",
                                                        rules: {
                                                          required: true,
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.date,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.date = $$v
                                                        },
                                                        expression: "date",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3",
                                                  },
                                                  [
                                                    _c("base-select", {
                                                      attrs: {
                                                        name: "treasury",
                                                        label:
                                                          "Seleziona una cassa",
                                                        options:
                                                          _vm.treasury_options,
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.onInputTreasury,
                                                      },
                                                      model: {
                                                        value: _vm.treasury,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.treasury = $$v
                                                        },
                                                        expression: "treasury",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3",
                                                  },
                                                  [
                                                    _c("base-checkbox", {
                                                      attrs: {
                                                        name: "importo differente",
                                                        label: "",
                                                        chk_val: true,
                                                        unchk_val: false,
                                                        isSwitch: true,
                                                        groupLabel:
                                                          "Importo differente",
                                                        disabled:
                                                          _vm.amount_due < 0 ||
                                                          !_vm.treasury,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.importo_differente,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.importo_differente =
                                                            $$v
                                                        },
                                                        expression:
                                                          "importo_differente",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm.treasury &&
                                                _vm.importo_differente
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c("base-currency", {
                                                          attrs: {
                                                            name: "importo",
                                                            vid: "gross",
                                                            label:
                                                              "Nuovo importo",
                                                            options: {
                                                              currency: "EUR",
                                                              locale: "it-IT",
                                                              precision: 2,
                                                            },
                                                          },
                                                          model: {
                                                            value: _vm.importo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.importo = $$v
                                                            },
                                                            expression:
                                                              "importo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "py-2 mb-4" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                type: "button",
                                                variant: "lisaweb",
                                                size: "sm",
                                                disabled:
                                                  !_vm.treasury ||
                                                  (_vm.importo_differente &&
                                                    _vm.importo === 0) ||
                                                  invalid,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return handleSubmit(
                                                    _vm.onUpdate
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              Incassa\n            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3265195234
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }