var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { ok: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "envelope", "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Invia selezionate")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel(), _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("base-select", {
            attrs: {
              vid: "template",
              label: "Seleziona un modello e-mail",
              name: "Template",
              options: _vm.options,
            },
            model: {
              value: _vm.inputVal.template_id,
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, "template_id", $$v)
              },
              expression: "inputVal.template_id",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "mt-2 ml-4 float-right",
          attrs: {
            type: "button",
            disabled: !_vm.inputVal.template_id,
            variant: "outline-lisaweb",
            size: "sm",
          },
          on: { click: _vm.onSend },
        },
        [_vm._v("\n    Invia\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }