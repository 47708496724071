var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1 mr-1 mb-5" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: { click: _vm.onAddBookEntry },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-create mb-1",
                      attrs: { type: "button", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onOpenGenerateBrokerStatements(
                            "commission_mode"
                          )
                        },
                      },
                    },
                    [_vm._v("Effettua Calcoli Provvigionali")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-create mb-1",
                      attrs: { type: "button", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onOpenGenerateBrokerStatements(
                            "broker_statement_mode"
                          )
                        },
                      },
                    },
                    [_vm._v("Genera Rendiconti")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              attrs: { id: "collapse-1" },
              model: {
                value: _vm.showCollapse1,
                callback: function ($$v) {
                  _vm.showCollapse1 = $$v
                },
                expression: "showCollapse1",
              },
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName, true)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "cod_prod",
                                label: "Produttore",
                                options: _vm.salesmen,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              on: { input: _vm.onSalesmen },
                              model: {
                                value: _vm.salesmen_ids,
                                callback: function ($$v) {
                                  _vm.salesmen_ids = $$v
                                },
                                expression: "salesmen_ids",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  pressed: _vm.myToggle,
                                  variant: "light",
                                },
                                on: {
                                  "update:pressed": function ($event) {
                                    _vm.myToggle = $event
                                  },
                                  click: function ($event) {
                                    _vm.myToggle
                                      ? _vm.selectAllBrokers()
                                      : _vm.deselectAllBrokers()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.myToggle
                                      ? "Deseleziona tutti"
                                      : "Seleziona tutti"
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "int_prod",
                                label: "Intestazione documenti prodotti",
                                options: _vm.intProd,
                              },
                              model: {
                                value: _vm.salesman_id,
                                callback: function ($$v) {
                                  _vm.salesman_id = $$v
                                },
                                expression: "salesman_id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "insurers",
                                label: "Compagnia",
                                options: _vm.insurers,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value: _vm.filter.byInsurer.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byInsurer, "id", $$v)
                                },
                                expression: "filter.byInsurer.id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "from",
                                label: "Registrazione Dal",
                              },
                              model: {
                                value: _vm.filter.byCalendar.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "from", $$v)
                                },
                                expression: "filter.byCalendar.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: { name: "to", label: "Registrazione Al" },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-checkbox", {
                              attrs: {
                                name: "arretrati",
                                label: "Includi",
                                chk_val: true,
                                unchk_val: false,
                                isSwitch: true,
                                groupLabel:
                                  "Includi registrazioni escluse dai rendiconti precedenti",
                              },
                              model: {
                                value: _vm.arretrati,
                                callback: function ($$v) {
                                  _vm.arretrati = $$v
                                },
                                expression: "arretrati",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-radio", {
                              attrs: {
                                name: "calculus_type",
                                label: "Modalità",
                                options: _vm.calculus_options,
                                stacked: "",
                              },
                              on: { select: _vm.onCalculusTypeChange },
                              model: {
                                value: _vm.calculus_type,
                                callback: function ($$v) {
                                  _vm.calculus_type = $$v
                                },
                                expression: "calculus_type",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-checkbox", {
                              attrs: {
                                disabled: !_vm.calculus_type,
                                name: "esclusi",
                                label: "Escludi",
                                chk_val: true,
                                unchk_val: null,
                                isSwitch: true,
                                groupLabel:
                                  "Escludi dal ricalcolo provvigionale Lisaweb le compagnie e i rami indicati nell'anagrafica Compagnie",
                              },
                              model: {
                                value: _vm.filter.byExclusion,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "byExclusion", $$v)
                                },
                                expression: "filter.byExclusion",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(
              "\n    Modalità Rendiconto:\n    " +
                _vm._s(_vm.$store.state.auth.settings.attributables.STATMOD) +
                "\n  "
            ),
          ]),
          _vm._v(" "),
          _vm.salesman_id
            ? _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n    :: Intestazione documenti prodotti:\n    " +
                    _vm._s(
                      _vm.salesmen.find((s) => s.value == _vm.salesman_id).text
                    ) +
                    " :: Dal\n    " +
                    _vm._s(_vm.filter.byCalendar.from) +
                    " :: Al " +
                    _vm._s(_vm.filter.byCalendar.to) +
                    "\n  "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("salesmen-accounting", {
            ref: _vm.tableRef,
            attrs: {
              filterOn: {
                byRelations: [
                  "byBookTag",
                  "byInsurer",
                  "byRegistry",
                  "byInsuranceAncillary",
                  "byVariousAccounting",
                ],
              },
              fields: _vm.flds,
              repository: _vm.repository,
              resource: _vm.resource,
              filterName: _vm.filterName,
              onlyActions: ["edit", "destroy"],
              includeBottomRow: "",
              noPagination: "",
              includeRowSelector: "",
              hasChecks: "",
            },
            on: {
              rowSelector: _vm.updateOne,
              cellChange: _vm.onCellChange,
              edit: _vm.onEdit,
              confirm: _vm.onConfirm,
              destroy: _vm.onDelete,
              rowSelector2: _vm.onStatementExclude,
            },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.$refs[_vm.tableRef] &&
                    _vm.$refs[_vm.tableRef].items.length,
                  expression: "$refs[tableRef] && $refs[tableRef].items.length",
                },
              ],
            },
            [
              _c("div", {
                staticStyle: {
                  width: "15px",
                  height: "15px",
                  background: "white",
                  "margin-left": "18px",
                  padding: "0",
                  "margin-top": "8px",
                  "font-size": "12px",
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "5px",
                    "margin-right": "5px",
                    padding: "0",
                    "margin-top": "8px",
                    "font-size": "12px",
                  },
                },
                [_vm._v("\n      Registrazione in intervallo date\n    ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticStyle: {
                  width: "15px",
                  height: "15px",
                  background: "pink",
                  padding: "0",
                  "margin-top": "8px",
                  "font-size": "12px",
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "5px",
                    "margin-right": "5px",
                    padding: "0",
                    "margin-top": "8px",
                    "font-size": "12px",
                  },
                },
                [_vm._v("\n      Registrazione arretrata\n    ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticStyle: {
                  width: "15px",
                  height: "15px",
                  background: "orange",
                  padding: "0",
                  "margin-top": "8px",
                  "font-size": "12px",
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "5px",
                    "margin-right": "5px",
                    padding: "0",
                    "margin-top": "8px",
                    "font-size": "12px",
                  },
                },
                [_vm._v("\n      Registrazione esclusa dal rendiconto\n    ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticStyle: {
                  width: "15px",
                  height: "15px",
                  background: "purple",
                  padding: "0",
                  "margin-top": "8px",
                  "font-size": "12px",
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "5px",
                    "margin-right": "5px",
                    padding: "0",
                    "margin-top": "8px",
                    "font-size": "12px",
                  },
                },
                [_vm._v("\n      Movimento vario modificabile\n    ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isUpd, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Aggiornamento in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1400280798
              ),
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isUpd,
                      expression: "!isUpd",
                    },
                  ],
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.$refs[_vm.tableRef] &&
                            _vm.$refs[_vm.tableRef].items.length,
                          expression:
                            "$refs[tableRef] && $refs[tableRef].items.length",
                        },
                      ],
                      staticClass: "mt-1",
                      attrs: {
                        type: "button",
                        variant: "lisaweb",
                        disabled: _vm.changedIds.length == 0,
                      },
                      on: { click: _vm.updateAll },
                    },
                    [_vm._v("Aggiorna Tutti")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.$refs[_vm.tableRef] &&
                            _vm.$refs[_vm.tableRef].items.length &&
                            _vm.$refs[_vm.tableRef].selectedRows.length &&
                            _vm.changedIds.length == 0,
                          expression:
                            "\n          $refs[tableRef] &&\n          $refs[tableRef].items.length &&\n          $refs[tableRef].selectedRows.length &&\n          changedIds.length == 0\n        ",
                        },
                      ],
                      staticClass: "mt-1",
                      attrs: {
                        type: "button",
                        variant: "lisaweb",
                        disabled: !_vm.canShowBrokerStatement,
                      },
                      on: { click: _vm.onReset },
                    },
                    [_vm._v("Ricarica")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.$refs[_vm.tableRef] &&
                            _vm.$refs[_vm.tableRef].items.length &&
                            _vm.$refs[_vm.tableRef].selectedRows.length &&
                            _vm.changedIds.length == 0,
                          expression:
                            "\n          $refs[tableRef] &&\n          $refs[tableRef].items.length &&\n          $refs[tableRef].selectedRows.length &&\n          changedIds.length == 0\n        ",
                        },
                      ],
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            type: "button",
                            variant: "lisaweb",
                            disabled: _vm.canShowBrokerStatement,
                          },
                          on: { click: _vm.drawBrokerStatement },
                        },
                        [_vm._v("Rendiconto")]
                      ),
                      _vm._v(" "),
                      _vm.canShowBrokerStatement
                        ? _c(
                            "div",
                            { staticClass: "mt-1 mb-1" },
                            [
                              _c("export-options", {
                                attrs: {
                                  exportUrl: _vm.exportUrl,
                                  repository: _vm.repository,
                                  resource: _vm.resource,
                                  tableRef: _vm.$refs[_vm.tableRef],
                                  filter: _vm.filter,
                                  options: [
                                    {
                                      code: "RENPROVUNOFFICIALIZED",
                                      label: null,
                                      formats: ["csv", "pdf"],
                                      extra: {
                                        produttori: _vm.smids, // REVIEW: still require this?
                                        salesman_id: _vm.smid,
                                      },
                                    },
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-card",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    header: _vm.getDictionary("balance_sheet"),
                                    "header-tag": "header",
                                  },
                                },
                                [
                                  _c("b-card-text", [
                                    _c("table", { staticClass: "summary" }, [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              attrs: {
                                                scope: "col",
                                                colspan: "2",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary("totals")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDictionary("premiums")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  _vm.summary.entrate
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary(
                                                      "of_which_deferred_to_collect"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  _vm.summary.sospesi
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary(
                                                      "advance_payments"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  _vm.summary.acconti
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDictionary(
                                                    "commissions"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.toLocaleCurrency(
                                                    _vm.summary.provvigioni
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary(
                                                      "of_which_not_taxable"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  _vm.summary.di_cui
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary(
                                                      "deduction"
                                                    )
                                                  ) +
                                                  " (" +
                                                  _vm._s(
                                                    _vm.summary.ritenuta_perc
                                                  ) +
                                                  "%)\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  _vm.summary.ritenuta
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary("fees")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  _vm.summary.di_cui_diritti
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary(
                                                      "commissions_to_liquidate"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.toLocaleCurrency(
                                                    _vm.summary.da_liquidare
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getDictionary(
                                                      "premiums_balance"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  _vm.summary.saldo
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-card",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    header: _vm.getDictionary(
                                      "make_official_balance_sheet"
                                    ),
                                    "header-tag": "header",
                                  },
                                },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("b-row", { staticClass: "mt-1" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-2" },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                name: _vm.getDictionary(
                                                  "invoice_number"
                                                ),
                                                label:
                                                  _vm.getDictionary(
                                                    "invoice_number"
                                                  ),
                                              },
                                              model: {
                                                value: _vm.form.invoice_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "invoice_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.invoice_number",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-2" },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                name: _vm.getDictionary(
                                                  "period"
                                                ),
                                                label:
                                                  _vm.getDictionary("period"),
                                              },
                                              model: {
                                                value: _vm.form.invoice_period,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "invoice_period",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.invoice_period",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group col-md-2 align-self-end",
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  type: "button",
                                                  variant: "lisaweb",
                                                  disabled: !_vm.salesman_id,
                                                },
                                                on: {
                                                  click:
                                                    _vm.onSaveBrokerStatement,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Ufficializza Rendiconto"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("generate-broker-statements-modal", {
                ref: _vm.generateBrokerStatementModalRef,
                attrs: {
                  title: _vm.broker_statement_title,
                  header: _vm.broker_statement_header,
                },
                on: { generate: _vm.onGenerate },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { ref: "anchor1" }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }